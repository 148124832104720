<template>
  <referentiel-list
  :listTitle="'Liste des antennes'"
  :referentielName="referentielName"
  :tableFields="tableFields"
  :emptyReferentielItem="emptyReferentielItem"
  >
  <template #formContent="{ handleInput, editableItem }">
    <div class="row">
      <div class="col-sm-12">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <input :value="editableItem.intitule" @input="handleInput" type="text" class="form-control" id="intitule" name="intitule" placeholder="Intitulé">
            <label for="name">Intitutlé</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
          </div>
        </ValidationProvider>
      </div>
      <div class="col-sm-12">
        <ValidationProvider rules="" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <input :value="editableItem.description" @input="handleInput" type="text" class="form-control" id="description" name="description" placeholder="Description">
            <label for="description">Description</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
          </div>
        </ValidationProvider>
      </div>

      <div class="col-sm-12">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <label class="typo__label">Département</label>
          <div class="form-floating mb-3">
            <multiselect
              v-model="editableItem.departement"
              :options="departements"
              :multiple="false"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Département"
              label="nom"
              track-by="nom"
              :preselect-first="false"
            />
            <span
              v-for="(err, index) in errors"
              :key="index"
              class="text-danger"
              >{{ err }}</span
            >
          </div>
        </ValidationProvider>
      </div>
      
      <div class="col-sm-12">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <label class="typo__label">Communnes</label>
            <div class="form-floating mb-3">
              <multiselect
                v-model="editableItem.communes"
                :options="communes"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Communes"
                label="nom"
                track-by="nom"
                :preselect-first="false"
              />
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
      </div>
    </div>
  </template>

  </referentiel-list>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Multiselect from "vue-multiselect";
import ReferentielList from '../../../components/common/referentiel/ReferentielList.vue'
export default {
  components: {
    ReferentielList,
    Multiselect
  },
  computed: {
    ...mapGetters({
      communes: 'commune/communes',
      departements: 'departement/departements',
      antennes: 'antenne/antennes',
      typeAntennes: 'antenne/typeAntennes'
    }),
    nullableAntennes () {
      return [ {}, ...this.antennes]
    },
    referentielName () {
      return 'antenne'
    },
    emptyReferentielItem () {
      return {
        intitule: null,
        description: null,
        departement: null,
        communes: []
      }
    },
    tableFields () {
      return [
        '#',
        'index',
        { key: 'intitule', label: 'Nom' },
        { key: 'description', label: 'Description' },
        { key: 'departement.nom', label: 'Département' },
        'actions',
      ]
    },
  },
  created () {
    this.fetchTypeAntennes()
    this.fetchCommunes()
    this.fetchDepartements()
  },
  methods: {
    ...mapActions({
      fetchAntennes: 'antenne/fetchAntennes',
      fetchTypeAntennes: 'antenne/fetchTypeAntennes',
      fetchCommunes: 'commune/fetchCommunes',
      // fetchDepartements: 'commune/fetchCommunes',
      fetchDepartements: 'departement/fetchDepartements',
    })
  }

}
</script>

<style>

</style>